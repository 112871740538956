<template>
  <a-spin :spinning="loading">

    <a-row v-if="isProductPresent">
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="simiot-descriptions simiot-descriptions-max-6"
        >
          <a-descriptions-item label="当前套餐">
            {{ data.product_name }}
          </a-descriptions-item>
          <a-descriptions-item label="用户业务模式">
            {{ data.user_business_type }}
          </a-descriptions-item>
          <a-descriptions-item label="订购时间">
            {{ data.subscribed_at }}
          </a-descriptions-item>
          <a-descriptions-item label="套餐周期数">
            {{ data.cycles }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="simiot-descriptions simiot-descriptions-max-8"
        >
          <a-descriptions-item label="套餐服务开始时间">
            {{ data.effective_time }}
          </a-descriptions-item>
          <a-descriptions-item label="套餐服务结束时间">
            {{ data.expiration_time }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="simiot-descriptions simiot-descriptions-max-6"
        >
          <a-descriptions-item label="周期序列">
            <a-select
              style="width: 100px"
              :value="data.sim_card_product_cycle_usage.cycle"
              @change="fetchSimCardProductCycleUsageData"
            >
              <a-select-option
                v-for="option in cycleOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-descriptions-item>

          <a-descriptions-item label="周期容量">
            {{ data.sim_card_product_cycle_usage.cycle_total }}
          </a-descriptions-item>
          <a-descriptions-item label="周期已用量">
            {{ data.sim_card_product_cycle_usage.cycle_usage }}
          </a-descriptions-item>
          <a-descriptions-item label="周期剩余量">
            {{ data.sim_card_product_cycle_usage.cycle_left }}
          </a-descriptions-item>
          <a-descriptions-item label="容量使用进度">
            {{ data.sim_card_product_cycle_usage.use_progress }}
          </a-descriptions-item>
          <a-descriptions-item label="周期语音时长">
            {{ data.sim_card_product_cycle_usage.voice_capacity }}
          </a-descriptions-item>
          <a-descriptions-item label="周期已用时长">
            {{ data.sim_card_product_cycle_usage.voice_usage }}
          </a-descriptions-item>
          <a-descriptions-item label="周期剩余时长">
            {{ data.sim_card_product_cycle_usage.voice_left }}
          </a-descriptions-item>
          <a-descriptions-item label="周期开始时间">
            {{ data.sim_card_product_cycle_usage.cycle_begin_time }}
          </a-descriptions-item>
          <a-descriptions-item label="周期结束时间">
            {{ data.sim_card_product_cycle_usage.cycle_end_time }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
    <a-empty v-else />
  </a-spin>
</template>

<script>
import { findSimCardCurrentPackage, findSimCardProductCycleUsage } from '@/api/sim_card'

export default {
  name: 'ShowSimCardCurrentPackage',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      cycleOptions: [],
      data: {}
    }
  },
  computed: {
    isProductPresent() {
      return this.data.id > 0
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchSimCardProductCycleUsageData(cycle) {
      findSimCardProductCycleUsage(this.id, { sim_cards_product_id: this.data.id, cycle: cycle }).then((res) => {
        this.data.sim_card_product_cycle_usage = res.data
      })
    },

    // 组装周期选项数据
    assembleCycleOptions(productInfo = {}) {
      const cycleOptions = []
      const totalCycle = productInfo.cycles

      for (let i = 1; i <= productInfo.sim_card_product_cycle_usage.cycle; i++) {
        cycleOptions.push({ label: `${i}/${totalCycle}`, value: i })
      }

      return cycleOptions
    },

    fetchData() {
      this.loading = true
      findSimCardCurrentPackage(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data

          if (this.isProductPresent) {
            this.cycleOptions = this.assembleCycleOptions(this.data)
          }
        }
        this.loading = false
      })
    }
  }
}
</script>
